document.addEventListener('DOMContentLoaded', function() {
  const dropdownToggle = document.querySelectorAll('.dropdown .dropdown-toggle');

  dropdownToggle.forEach(node => {
    node.addEventListener('click', function() {
      node.nextSibling.classList.toggle('hidden');
    });
  });

});
